.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  color: var(--text-black);
  background-color: var(--pure-white);

  .dark & {
    background-color: var(--main-bg-color);
    color: var(--text-white);
  }

  /* Logo Section */
  .footer-logo {
    margin-bottom: 1rem;

    .footer-logo-img {
      width: 9rem;
      height: 3rem;
    }
  }

  /* Main Content (Links + Community & Newsletter) */
  .footer-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    // gap: 2rem;

    /* Footer Links */
    .footer-links {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      flex: 2;

      .footer-section {
        min-width: 150px;

        h4 {
          font-size: var(--font-size-16);
          font-weight: bold;
          margin-bottom: 1rem;
          color: var(--text-black);

          .dark & {
            color: var(--text-white);
          }
        }

        a {
          display: block;
          margin-bottom: 0.4rem;
          color: var(--text-color);
          text-decoration: none;
          transition: color 0.3s ease;

          &:hover {
            color: var(--text-black);
          }
          .dark & {
            &:hover {
              color: var(--text-white);
            }
          }
        }
      }
    }

    /* Community & Newsletter */
    .footer-community {
      flex: 1;
      min-width: 250px;

      h4 {
        font-size: var(--font-size-16);
        font-weight: bold;
        margin-bottom: 1rem;
        color: var(--text-black);

        .dark & {
          color: var(--text-white);
        }
      }

      /* Social Icons */
      .footer-social {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        margin-bottom: 2rem;

        .footer-social-icon {
          cursor: pointer;
          color: var(--icon-color);
          transition: color 0.3s;

          &:hover {
            color: var(--text-black);
          }

          .dark & {
            &:hover {
              color: var(--text-white);
            }
          }
        }
      }

      /* Newsletter Subscription */
      .footer-subscription {
        margin-bottom: 2rem;

        p {
          color: var(--text-color);
          margin-bottom: 1rem;
          font-size: var(--font-size-14);
        }

        .footer-subscribe-input {
          display: flex;
          align-items: center;
          position: relative;

          input {
            flex: 1;
            padding: 1rem;
            border: 1px solid var(--search-border-color);
            border-radius: 4px;
            font-size: var(--font-size-18);
            color: var(--text-white);
            margin-right: 1rem;
            .dark & {
              background-color: var(--main-bg-color);
              border: 1px solid var(--search-border-color);
            }

            &:focus {
              outline: none;
              border-color: var(--primary-color);
            }

            &::placeholder {
              color: var(--text-color);
            }
          }

          button {
            border-radius: 4px;
            cursor: pointer;
            font-weight: 500;
            position: absolute;
            right: 20px;
            top: 8px;
            font-size: var(--font-size-18);

            &:hover {
              background-color: var(--ai-icon-hover-bg-color);
            }
          }
        }

        .footer-error {
          color: var(--border-color-error);
          margin-top: 0.5rem;
        }

        .footer-success {
          color: var(--border-color-success);
          margin-top: 0.5rem;
        }
      }
    }
  }

  /* Bottom Section */
  .footer-bottom {
    margin-top: 1rem;
    color: var(--text-color);
    font-size: var(--font-size-16);
  }
}
