.modal-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(20, 21, 23, 0.8);
  backdrop-filter: blur(8px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;

  .modal-image {
    margin-top: 80px;
    width: 800px;
    height: 600px;
    border-radius: 5%;
    cursor: pointer;
  }
}
