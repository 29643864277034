/* / Box sizing rules / */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* / Remove default padding / */
ul[class],
ol[class] {
  padding: 0;
}

/* / Remove default margin / */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

/* / Set core body defaults / */
body {
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  min-height: 100vh;
}

/* / Remove list styles on ul, ol elements with a class attribute / */
ul[class],
ol[class] {
  list-style: none;
}

/* / A elements that don't have a class get default styles / */
a:not([class]) {
  text-decoration-skip-ink: auto;
}
//
/* / Natural flow and rhythm in articles by default / */
article > * + * {
  margin-top: 1em;
}

/* / Remove all animations and transitions for people that prefer not to see them / */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

a {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}

/* Resetting the default scrollbar styles */
/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px var(--grey-color);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(187, 187, 187);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(178, 178, 178);
}
/* Resetting the default scrollbar styles */
