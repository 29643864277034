.cta-button {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 150px;
  height: 2.5rem;
  padding: 1rem;
  background-color: var(--button-primary-color);
  color: var(--button-primary-text-color);
  font-size: var(--font-size-14);
  font-weight: 500;
  border: none;
  border-radius: 50px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: var(--button-primary-hover-color);
  }

  .before-icon {
    margin-right: 0.2rem;
  }
  .after-icon {
    margin: 0 0.5rem;
  }
}
