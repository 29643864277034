.download-popup {
  position: relative;
  width: 800px;
  height: 600px;
  background-color: var(--pure-white);
  border-radius: 20px;
  overflow: hidden;

  .dark & {
    background-color: var(--card-dark-bg);
  }

  /* Background Layer */
  &__bg-layer {
    position: absolute;
    width: 800px;
    height: 600px;
    opacity: 0.2;

    &__bg-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 800px;
      height: 600px;
      border-radius: 9999px;
      background-color: var(--text-black);
      opacity: 0.3;
      content: '';
    }

    /* Blur + shadow overlay */
    &__bg-blur {
      position: absolute;
      top: 0;
      left: 0;
      width: 800px;
      height: 600px;
      border-radius: 9999px;
      background-color: #d9d9d9;
      filter: blur(240px);
      box-shadow: 0 0 240px rgba(0, 0, 0, 0.25);
      content: '';
    }

    &__bg-image {
      width: 800px;
      height: 600px;
      transform: scale(1.5);
      object-fit: cover;
    }
  }

  &__title {
    position: absolute;
    top: 30px;
    left: 193px;
    text-align: center;
    color: var(--card-dark-bg);

    font-size: 31px;
    font-weight: 300;
    line-height: 1.2;
    z-index: 2;

    .dark & {
      color: var(--pure-white);
    }
  }

  /* Phones & QR Container */
  .download-popup__phones-qr {
    /* Phone #1 */
    .download-popup__phone1 {
      position: absolute;
      top: 82%;
      left: 40%;
      transform: translate(0, -50%);
      width: 222px;
      height: 460px;
      object-fit: contain;
    }

    /* Phone #2 */
    .download-popup__phone2 {
      position: absolute;
      top: 26%;
      left: 60%;
      width: 276px;
      height: 572px;
      object-fit: contain;
    }

    /* QR code */
    .download-popup__qrcode {
      position: absolute;
      top: 38%;
      left: 1rem;
      width: 276px;
      height: 572px;
      object-fit: contain;
    }
  }

  /* Close Icon */
  &__close-icon {
    position: absolute;
    top: 30px;
    left: 746px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    filter: invert(1);

    .dark & {
      filter: invert(0);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
