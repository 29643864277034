.page-container {
  background-color: var(--pure-white);
  height: 100%;
  position: relative;
  margin-bottom: -50px;

  .dark & {
    background-color: var(--main-bg-color);
  }
}

.header {
  position: sticky;
  top: 0;
  z-index: 10000;
  width: 100%;
}

.footer-container {
  width: 80%;
  margin: auto;
}
