.error-boundary-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px;
  text-align: center;
}

.error-boundary-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.error-boundary-content {
  padding: 20px 40px;
  border: 1px solid transparent; /* Optional: Keeps structure */
  border-radius: 8px;

  h2,
  h3 {
    margin: 8px 0;
  }
}

.error-boundary-button {
  margin-top: 20px;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 16px;
}
