.navbar-home {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 12rem;
  background-color: var(--pure-white);
  color: var(--main-text-color);
  width: 100%;

  .dark & {
    background-color: var(--main-bg-color);
    color: var(--navbar-text-color);
  }

  .home-logo-container {
    flex: 1;

    .logo {
      width: 9rem;
      height: 3rem;
      animation: fadeIn 0.2s ease-in-out;

      @keyframes fadeIn {
        from {
          opacity: 0.5;
        }
        to {
          opacity: 1;
        }
      }
    }
  }

  .actions {
    display: flex;
    align-items: center;

    .download-button {
      margin-right: 1rem;
    }
  }
}
