.main-layout-layout {
  display: flex;
  height: 100vh;
  background-color: var(--navbar-text-color);
  color: var(--text-black);

  overflow: hidden;

  .dark & {
    background-color: var(--navbar-bg-color);
    color: var(--main-text-color);
  }
}

.main-layout-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.main-layout-main {
  padding: 20px;
  flex-grow: 1;
  overflow-y: auto;
}

.main-layout-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: var(--loading-bg-color);
}

.main-layout-ai-icon {
  position: absolute;
  bottom: 20px;
  right: 20px;
  color: var(--ai-icon-text-color);
  padding: 10px 15px;
  cursor: pointer;
  transition: transform 0.5s ease;
  z-index: 99999;
}

.main-layout-ai-icon:hover {
  transform: scale(1.2);
}
