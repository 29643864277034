.search-bar {
  display: flex;
  justify-content: center;
  margin: 3rem 0;
  width: 100%;
  max-width: 500px;
  position: relative;

  .input-container {
    position: relative;
    width: 100%;
    max-width: 500px;

    input {
      width: 100%;
      padding: 0.7rem 1rem 0.7rem 3rem;
      border: 0.5px solid var(--text-color);
      border-radius: 50px;
      font-size: var(--font-size-18);
      background-color: transparent;
      outline: none;
      transition: border-color 0.3s;
      color: var(--text-black);
      font-weight: 500;

      .dark & {
        border: 1px solid var(--text-white);
        background-color: var(--search-bg-color);
        color: var(--navbar-text-color);
      }

      &.error {
        border-color: var(--border-color-error);
      }

      &.success {
        border-color: var(--border-color-success);
      }

      &::placeholder {
        color: var(--text-color);

        .dark & {
          color: var(--navbar-text-color);
        }
      }
    }

    .search-icon {
      position: absolute;
      left: 1.25rem;
      top: 48%;
      transform: translateY(-50%);
      width: 1.25rem;
      height: 1.25rem;
      display: none;
      filter: invert(0);

      @media (min-width: 768px) {
        display: block;
      }

      .dark & {
        filter: invert(1);
      }
    }

    .icon {
      cursor: pointer;

      svg {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        height: 34px;
        width: 34px;
        background-color: var(--card-dark-bg);
        border-radius: 9999px;
        padding: 0.5rem;
        right: 4px;
        animation: fade-in 0.3s ease-in-out forwards;
      }

      &.disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }

  .feedback-message {
    text-align: center;
    font-size: var(--font-size-14);
    font-weight: 500;
    line-height: 25px;
    margin-top: 0.5rem;
    position: absolute;
    top: 100%;

    &.success {
      color: var(--border-color-success);
    }

    &.error {
      color: var(--border-color-error);
    }
  }
}
