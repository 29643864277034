:root {
  --primary-color: #004a54;
  --primary-dark-color: rgba(2, 65, 74, 1);
  --gradient-primary: linear-gradient(to right, #fa6c6c, #0669f8);
  --gradient: linear-gradient(257deg, #0669f8 0%, #ff7777 100%);
  --sidebar-bg-color: #1a1d20;
  --sidebar-light-bg-color: #f1f5f9;
  --navbar-bg-color: #101113;
  --navbar-text-color: #ffffff;
  --navbar-border-bottom-color: rgb(236, 236, 236);
  --navbar-border-color: rgb(26 29 32 / 1);
  --active-border-color: #0669f8;
  --text-color: #6d7072;
  --text-black: #000000;
  --text-white: #fff;
  --hover-light-bg-color: #e2e8f0;
  --hover-bg-color: #343a40;
  --search-border-color: #ccc;
  --search-bg-color: #101113;
  --loading-bg-color: #fff;
  --button-primary-text-color: #ffffff;
  --button-primary-color: #0669f8;
  --button-primary-hover-color: rgba(29, 78, 216, 1);
  --border-color-success: #28a745;
  --border-color-error: #dc3545;
  --icon-color: #6d7072;
  --ai-icon-bg-color: #007bff;
  --ai-icon-hover-bg-color: #0056b3;
  --ai-icon-text-color: #fff;
  --pure-white: #ffffff;
  --milk-white: #f3f8fb;
  --main-bg-color: #141719;
  --main-text-color: #333;
  --hero-bg-color: #141719;
  --hero-border-color: #1a1d20;
  --circle-border-color: #0669f8;
  --circle-gradient-start: #ff7777;
  --circle-gradient-end: #0669f8;
  --circle-bg-overlay-start: #12bde2;
  --circle-bg-overlay-end: #0669f8;
  --circle-opacity: 0.1;
  --ape-bg: rgba(255, 255, 255, 0.2);
  --ape-border: rgba(255, 255, 255, 0.5);
  --bg-main: #131719;
  --border-main: #1a1d20;
  --circle-from: #0669f8;
  --circle-to: #ff7777;
  --card-dark-bg: #1a1d20;
  --card-bg: #d2d2d2;
  --text-gray: #6d7072;
  --main-ai-bg-color: #101113;
  --primary-ai-color: #0669f8;
  --secondary-ai-color: #ff7777;
  --gray-ai-dark: #2b3140;
  --accent-ai-dark: #25292d;
  --accent-ai-black: #25292d;
  --border-radius: 20px;
  --bg-color: #101113;
  --bg-calendar: #080a11;
  --default-font-family: ui-sans-serif, system-ui, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  --font-family-nunito: 'Nunito sans';
  --font-size-10: 10px;
  --font-size-12: 12px;
  --font-size-14: 14px;
  --font-size-16: 16px;
  --font-size-18: 18px;
  --font-size-24: 24px;
  --font-size-28: 28px;
  --font-size-30: 30px;
  --font-size-32: 32px;
  --font-size-40: 40px;
  --success-green: #22c55e;
  --error-red: #ef4444;
  --text-dark: #1a202c;
  --border-radius-8: 8px;
  --dropdown-border-color: #25292d;
  --dropdown-hover-light: #f9f9f9;
  --dropdown-hover-dark: #141919;
  --dropdown-option-light: #f2f2f2;
  --dropdown-active-light: #e6f7ff;
  --dropdown-active-dark: #2c2f33;
  --gray-600: #6b7280;
}
