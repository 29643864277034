/* SafleAI.scss */

.safle-ai {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  width: 30%;
  min-width: 467px;
  height: 85%;
  background-color: var(--pure-white);
  color: var(--text-black);
  border: 1px solid var(--text-gray);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  .dark & {
    border: none;
    background-color: var(--main-bg-color);
    color: var(--text-white);
  }

  .safle-ai__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: #eff1f7;
    border-bottom: 1px solid var(--border-main);

    .dark & {
      background-color: #25292d;
    }

    &-left {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }

    &-right {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }

    .safle-ai__ai-icon {
      width: 40px;
      height: 40px;
    }

    .safle-ai__title {
      font-size: var(--font-size-16);
      font-weight: 600;
    }

    .safle-ai__close-icon {
      width: 17px;
      height: 17px;
      cursor: pointer;
      filter: invert(29%) sepia(20%) saturate(277%) hue-rotate(192deg);

      .dark & {
        filter: invert(1);
      }
    }

    .safle-ai__active-token {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding: 0.25rem 1rem;
      background-color: var(--pure-white);
      // border: 1px solid var(--dropdown-border-color);
      border-radius: 8px;

      .dark & {
        background-color: var(--main-bg-color);
      }
    }

    .safle-ai__token-logo {
      width: 20px;
      height: 20px;
      border-radius: 10px;
    }

    .safle-ai__token-details {
      display: flex;
      flex-direction: column;
    }

    .safle-ai__token-symbol {
      font-size: var(--font-size-14);
      font-weight: 500;
      color: var(--text-black);

      .dark & {
        color: var(--text-white);
      }
    }

    .safle-ai-token-name {
      font-size: var(--font-size-12);
      color: var(--text-color);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .safle-ai__chat-area {
    flex-grow: 1;
    padding: 1rem;
    overflow-y: auto;

    .no-scrollbar {
      scrollbar-width: none; /* Firefox */
    }

    .no-scrollbar::-webkit-scrollbar {
      display: none; /* Chrome, Safari, Opera */
    }

    .safle-ai__welcome {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      color: var(--text-gray);
    }

    .safle-ai__nft-description {
      font-size: var(--font-size-12);
    }

    .safle-ai__nft-image-container {
      width: 100%;
      height: 160px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 1rem 0;
    }

    .safle-ai__nft-image {
      max-height: 400px;
      object-fit: cover;  
      border-radius: 8px;
    }

    .safle-ai__greeting {
      font-size: var(--font-size-14);
      color: var(--text-color);
      font-weight: 500;
    }

    .safle-ai__predefined-questions {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      margin-top: 0.5rem;
    }

    .safle-ai__question {
      padding: 0.5rem;
      border: 1px solid var(--hover-light-bg-color);
      border-radius: 8px;
      background-color: var(--pure-white);
      cursor: pointer;
      transition: background-color 0.2s;

      &:hover {
        background-color: var(--hover-light-bg-color);
      }

      .dark & {
        background-color: var(--accent-ai-dark);
        border-color: var(--dropdown-hover-dark);

        &:hover {
          background-color: var(--hover-bg-color);
        }
      }
    }

    .safle-ai__messages {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    .safle-ai__message {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: 0.5rem;

      &--assistant {
        justify-content: flex-start;
      }

      &--user {
        justify-content: flex-end;
      }
    }

    .safle-ai__message-content {
      max-width: 80%;
      padding: 0.75rem;
      border: 1px solid var(--dropdown-border-color);
      border-radius: 8px;
      background-color: var(--pure-white);
      color: var(--text-black);
      font-size: var(--font-size-14);
      word-wrap: break-word;
      white-space: pre-wrap;

      .dark & {
        background-color: var(--accent-ai-dark);
        color: var(--text-white);
        border-color: var(--dropdown-hover-dark);
      }
    }

    .safle-ai__streaming-response {
      padding: 0.75rem;
      border: 1px solid var(--dropdown-border-color);
      border-radius: 8px;
      background-color: var(--pure-white);
      color: var(--text-black);
      font-size: var(--font-size-14);
      word-wrap: break-word;
      white-space: pre-wrap;

      .dark & {
        background-color: var(--accent-ai-dark);
        color: var(--text-white);
        border-color: var(--dropdown-hover-dark);
      }
    }
  }

  .safle-ai__input-area {
    padding: 1rem;
    border-top: 1px solid var(--border-main);
    background-color: #eff1f7;

    .dark & {
      background-color: #25292d;
    }

    .safle-ai__input {
      width: 100%;
      padding: 0.75rem 1rem;
      border: 1px solid var(--dropdown-border-color);
      border-radius: var(--border-radius);
      background-color: var(--pure-white);
      color: var(--text-black);
      font-size: var(--font-size-16);
      font-weight: 500;
      outline: none;
      transition: opacity 0.2s;

      &--disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }

      .dark & {
        background-color: var(--search-bg-color);
        color: var(--navbar-text-color);
        border: 1px solid var(--text-white);
      }

      &::placeholder {
        color: var(--text-color);

        .dark & {
          color: var(--navbar-text-color);
        }
      }
    }
  }

  /* Responsive Styles */
  @media (max-width: 1024px) {
    & {
      width: 40%;
    }

    .safle-ai__header {
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;
    }
  }

  @media (max-width: 768px) {
    & {
      width: 50%;
    }

    .safle-ai__header {
      padding: 0.75rem;
      top: -10px;
    }

    .safle-ai__predefined-questions {
      gap: 0.25rem;
    }

    .safle-ai__input-area {
      padding: 0.75rem;
    }

    .safle-ai__input {
      font-size: var(--font-size-14);
      padding: 0.5rem 0.75rem;
    }
  }

  @media (max-width: 480px) {
    & {
      width: 90%;
      min-width: unset;
    }

    .safle-ai__header {
      padding: 0.5rem;
      top: -5px;
    }

    .safle-ai__predefined-questions {
      gap: 0.1rem;
    }

    .safle-ai__input-area {
      padding: 0.5rem;
    }

    .safle-ai__input {
      font-size: var(--font-size-12);
      padding: 0.5rem 0.5rem;
    }
  }
}
