.gradient-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full screen height */
  background-color: #f6f6f6;
  width: 100%;
}

.dark-spin {
  background-color: #101113;
  .gradient-background {
    background-color: #101113;
  }
}
.gradient-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.rotating-gradient {
  position: relative;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background: linear-gradient(to right, blue, purple, pink);
  animation: spin 4s linear infinite;
  z-index: 10;
}

.gradient-background {
  position: absolute;
  width: 197px;
  height: 197px;
  background-color: #f6f6f6;
  border-radius: 50%;
  margin: 1px;
  z-index: 20;
}

.logo-container {
  position: absolute;
  text-align: center;
  z-index: 30;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
