.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  background-color: var(--navbar-text-color);
  border-bottom: 1px solid var(--navbar-border-bottom-color);
  color: var(--navbar-bg-color);

  .dark & {
    background-color: var(--navbar-bg-color);
    border-bottom: 1px solid var(--navbar-border-color);
    color: var(--navbar-text-color);
  }
}

.navbar-title {
  flex: 0.5;
}

.navbar-title h1 {
  margin: 0;
  font-size: 24px;
}

.navbar-search {
  flex: 1;
  margin: 0 20px;
  display: flex;
  align-items: center;
  position: relative;

  .search-bar {
    margin: 1.5rem 0;

    .feedback-message {
      line-height: 12px;
    }
  }
}

.navbar-actions {
  display: flex;
  align-items: center;
}

.navbar-actions button {
  margin-right: 10px;
  padding: 5px 10px;
  cursor: pointer;
}

.navbar-actions span {
  cursor: pointer;
}
