.loader {
  margin: 1rem auto;
  width: 2.5rem;
  height: 2.5rem;
  border: 4px 0 solid transparent;
  border-top: 4px solid var(--ai-icon-bg-color);
  border-radius: 50px;
  animation: spin 1.2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
