.coming-soon-mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background-color: var(--bg-black);
  color: var(--text-white);
  background-image: url('../../../assets/svg/BG_MOBILE.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  & > * {
    text-align: center;
  }

  &::before {
    content: 'Coming Soon!';
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--text-white);
    background-color: rgba(0, 0, 0, 0.5);
    padding: 1rem 2rem;
    border-radius: 10px;
  }

  @media (max-width: 480px) {
    &::before {
      font-size: 1.2rem;
      padding: 0.75rem 1.5rem;
    }
  }
}
