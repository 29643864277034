.sidebar {
  width: 260px;
  background-color: var(--sidebar-light-bg-color);
  color: var(--text-color);
  font-weight: 500;
  display: flex;
  flex-direction: column;
  transition: width 0.5s ease;
  position: relative;

  .dark & {
    background-color: var(--sidebar-bg-color);
  }

  @media (max-width: 768px) {
    width: 200px;
    &.collapsed {
      width: 60px;
    }
  }
  @media (max-width: 480px) {
    width: 100%;
    position: fixed;
    height: 100%;
    z-index: 1000;

    .sidebar-toggle {
      top: 20px;
      right: -24px !important;
    }
  }

  &.collapsed {
    width: 80px;

    .navlink {
      justify-content: center;
    }
  }

  .sidebar-toggle {
    border: none;
    display: flex;
    cursor: pointer;
    margin: 10px;
    position: absolute;
    right: -28px;
    top: 75px;
    background-color: transparent;
    transition: transform 0.5s ease;
    align-items: center;
  }

  .rotateExpanded {
    transform: rotate(-180deg);
  }

  .sidebar-logo {
    text-align: center;
    margin: 40px;
    display: flex;
    justify-content: center;

    h3 {
      margin-top: 10px;
      font-size: 18px;
    }
  }

  .sidebar-nav {
    overflow: hidden;
    ul {
      list-style: none;
      padding: 0;

      .navlink {
        padding: 8px 20px;
        display: flex;
        margin: 10px 0;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: var(--hover-light-bg-color);
          .dark & {
            background-color: var(--hover-bg-color);
          }
        }

        &.navlink-active {
          border-left: 5px solid var(--active-border-color);
          padding: 10px 20px 10px 15px;
          color: var(--text-black);
          transition: color 0.3s ease;
          .dark & {
            color: var(--text-white);
          }
        }

        .navlink-icon {
          margin-right: 10px;
          font-size: 16px;
        }

        .navlink-title {
          display: inline-block;
          white-space: nowrap;
        }
      }
    }
  }

  .sidebar-footer {
    margin-top: auto;
    text-align: center;
    padding: 0;
    overflow: hidden;
    white-space: nowrap;

    .social-media-links {
      margin: 20px 0px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      row-gap: 20px;
      column-gap: 24px;

      .social-media-icon {
        color: var(--icon-color);
        font-size: 18px;
      }
    }

    .terms-external-links {
      text-align: left;
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding-bottom: 20px;

      .external-links {
        cursor: pointer;
        display: flex;
        gap: 5px;
        padding: 8px 20px;
        &:hover {
          background-color: var(--hover-bg-color);
        }
      }
    }
  }
}
